import React from 'react';
import './PozarniUcpavky.css';

function PozarniUcpavky() {
  return (
    <div className="pozarni-ucpavky-container">
      <section className="pozarni-ucpavky-content">
        <h1>Požární ucpávky</h1>
        <h2>Požární ucpávky – Klíčový prvek vaší požární ochrany</h2>

        <p>
          Požární ucpávky hrají zásadní roli v ochraně budov před šířením požáru. Správně instalované požární ucpávky mohou zachránit životy, omezit škody na majetku a zajistit, že váš objekt splňuje všechny potřebné bezpečnostní normy. Naše firma se specializuje na profesionální instalaci a údržbu požárních ucpávek, které jsou navrženy tak, aby poskytovaly maximální ochranu.
        </p>

        <h2>Naše služby v oblasti požárních ucpávek zahrnují:</h2>
        <ul>
          <li>
            <strong>Návrh a instalace požárních ucpávek:</strong> Navrhujeme a instalujeme požární ucpávky přesně podle specifikací vašeho objektu. Naše řešení zajišťují, že požární ucpávky jsou správně umístěny a funkční v souladu s nejnovějšími normami.
          </li>
          <li>
            <strong>Údržba a opravy:</strong> Nabízíme servisní služby, které zahrnují údržbu a opravy stávajících požárních ucpávek, aby byla zajištěna jejich dlouhodobá spolehlivost a účinnost.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default PozarniUcpavky;
