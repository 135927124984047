import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PasswordPage.css';

function PasswordPage() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'el159159') {
      localStorage.setItem('accessGranted', 'true');
      navigate('/home');
    } else {
      setError('Nesprávné heslo.');
    }
  };

  return (
    <div className="password-page-container">
      <div className="message-box">
        <h1>Vstup na stránky</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="Zadejte heslo"
            value={password}
            onChange={handlePasswordChange}
          />
          <button type="submit">Přístup na stránky</button>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default PasswordPage;
