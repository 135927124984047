import React from 'react';
import './SpravaBudov.css';

function SpravaBudov() {
  return (
    <div className="sprava-budov-container">
      <section className="sprava-budov-content">
        <h1>Správa budov</h1>
        <h2>Správa budov – Komplexní péče o váš majetek</h2>

        <p>
          Správa budov je více než jen údržba. Je to komplexní služba, která zahrnuje správu technických, administrativních a ekonomických aspektů nemovitostí, aby váš majetek zůstal ve výborném stavu a jeho hodnota se zvyšovala. Naše firma nabízí profesionální služby správy budov v oblasti elektro, které vám umožní plně se soustředit na své hlavní podnikání, zatímco my se postaráme o všechno ostatní.
        </p>

        <h2>Naše služby v oblasti správy budov zahrnují:</h2>
        <ul>
          <li>
            <strong>Technická správa a údržba:</strong> Zajišťujeme pravidelnou údržbu technických zařízení, jako jsou vytápění, ventilace, klimatizace (HVAC), elektroinstalace a další infrastruktura. Provádíme také pravidelné revize a zajišťujeme rychlé opravy případných závad.
          </li>
          <li>
            <strong>Energetické řízení:</strong> Optimalizujeme spotřebu energií v budově pomocí moderních technologií a postupů, což vede ke snížení provozních nákladů a zvýšení energetické účinnosti.
          </li>
          <li>
            <strong>Zajištění bezpečnosti:</strong> Implementujeme a spravujeme bezpečnostní systémy, včetně elektronického zabezpečení, požární ochrany a kontrol přístupu, aby byl váš objekt chráněn před všemi druhy rizik.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default SpravaBudov;
