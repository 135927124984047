import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <Link to ="/home">
            <img src={logo} alt="Elrama s.r.o. Logo" />
          </Link>
        </div>
        <nav className={isOpen ? "nav open" : "nav"}>
          <ul>
            <li><Link to="/home" onClick={toggleMenu}>Domů</Link></li>
            <li><Link to="/about" onClick={toggleMenu}>O nás</Link></li>
            <li><Link to="/contact" onClick={toggleMenu}>Kontakt</Link></li>
          </ul>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </header>
  );
}

export default Header;
