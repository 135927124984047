import React from 'react';
import './Contact.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa';

function Contact() {
  return (
    <div className="contact-container">
      <section className="contact">
        <div className="contact-details">
          <h2>Sídlo firmy</h2>
          <p>Elrama s.r.o.</p>
          <p>Husinecká 903/10, 130 00 Praha 3</p>
          <p>IČO: 14448017</p>
          <p>DIČ: CZ14448017</p>
          <p><FaPhone /> +420 776 884 411</p>
          <p><FaEnvelope /> info@elrama.cz</p>

          <h2 className="section-break">Kancelář a sklad</h2> {/* Přidání třídy section-break */}
          <p>Provozní doba: Po-Pá 8:00-15:00</p>
          <p>Elrama s.r.o.</p>
          <p>Pražská 3227, 415 01 Teplice</p>
          <p>IČO: 14448017</p>
          <p>DIČ: CZ14448017</p>
          <p><FaPhone /> +420 776 884 411</p>
          <p><FaEnvelope /> info@elrama.cz</p>
        </div>
        <div className="contact-map">
        <iframe
                src="https://www.google.com/maps/embed?pb=AIzaSyD65DY2oX6uY5hPbu0A4F5i3g54T3FkgTE"
                width="600"
                height="450"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
            />
        </div>
      </section>
    </div>
  );
}

export default Contact;
