import React from 'react';
import './EZS.css';

function EZS() {
  return (
    <div className="ezs-container">
      <section className="ezs-content">
        <h1>Elektronické zabezpečovací systémy (EZS)</h1>
        <h2>Zajistěte bezpečí vašeho majetku.</h2>

        <p>
          Bezpečnost vašeho domova, kanceláře nebo průmyslového objektu je naší prioritou. Elektronické zabezpečovací systémy (EZS) představují moderní a efektivní způsob, jak chránit váš majetek před neoprávněným vniknutím a dalšími hrozbami. Nabízíme komplexní řešení v oblasti EZS, které zahrnují:
        </p>

        <h2>Naše služby v oblasti EZS zahrnují:</h2>
        <ul>
          <li>
            <strong>Návrh a instalace EZS:</strong> Poskytujeme profesionální návrh zabezpečovacího systému na míru podle vašich specifických potřeb. Naši odborníci navrhnou optimální řešení pro váš objekt, ať už jde o malý byt, rodinný dům, nebo rozsáhlý komerční či průmyslový areál.
          </li>
          <li>
            <strong>Integrace s dalšími systémy:</strong> Naše EZS mohou být integrovány s dalšími bezpečnostními systémy, jako jsou kamerové systémy (CCTV), přístupové systémy, nebo požární signalizace, což umožňuje komplexní a centralizovanou správu bezpečnosti.
          </li>
          <li>
            <strong>Dálkový monitoring a ovládání:</strong> Moderní EZS nabízejí možnost dálkového monitoringu a ovládání přes mobilní aplikace. Můžete tak mít přehled o dění ve vašem objektu odkudkoli na světě a okamžitě reagovat na případné hrozby.
          </li>
          <li>
            <strong>Údržba a servis EZS:</strong> Zajišťujeme pravidelnou údržbu a servisní kontroly vašich zabezpečovacích systémů, aby byly vždy v optimálním stavu a připraveny na případný útok.
          </li>
        </ul>

        <h2>Výhody elektronických zabezpečovacích systémů:</h2>
        <ul>
          <li>
            <strong>Zvýšená ochrana:</strong> Naše EZS poskytují spolehlivou ochranu před neoprávněným vniknutím, vandalismem a dalšími riziky, které mohou ohrozit váš majetek.
          </li>
          <li>
            <strong>Technologická inovace:</strong> Využíváme nejmodernější technologie, které zajišťují vysokou úroveň zabezpečení a snadné ovládání systému.
          </li>
          <li>
            <strong>Přizpůsobitelnost:</strong> Naše systémy jsou modulární a mohou být přizpůsobeny specifickým potřebám každého zákazníka, včetně možností rozšíření a aktualizací.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default EZS;
