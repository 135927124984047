import React from 'react';
import './Revize.css';

function Revize() {
  return (
    <div className="revize-container">
      <section className="revize-content">
        <h1>Revize a zkoušky elektrických zařízení</h1>
        <h2>Revize a zkoušky elektrických zařízení – Záruka bezpečnosti a spolehlivosti</h2>

        <p>
          Pravidelné revize a zkoušky elektrických zařízení jsou nezbytné pro zajištění bezpečnosti a správného fungování elektrických systémů ve vašem objektu. Tato služba nejenže pomáhá předcházet potenciálním rizikům, ale také zajišťuje, že vaše zařízení odpovídá všem platným normám a předpisům. Naše firma nabízí profesionální služby v oblasti revizí a zkoušek, které vám poskytnou jistotu a klid.
        </p>

        <h2>Naše služby zahrnují:</h2>
        <ul>
          <li>
            <strong>Revize elektrických instalací:</strong> Provádíme kompletní revize elektrických rozvodů a instalací v budovách, které zahrnují kontrolu všech komponent a jejich správného zapojení. Na základě výsledků revize obdržíte podrobnou zprávu o stavu instalace a případných potřebných úpravách.
          </li>
          <li>
            <strong>Zkoušky elektrických zařízení:</strong> Zajišťujeme zkoušky elektrických zařízení, které ověřují jejich správnou funkci a bezpečnost. Tyto zkoušky jsou prováděny v souladu s aktuálními normami a předpisy, aby bylo zajištěno, že zařízení funguje bezpečně a efektivně.
          </li>
          <li>
            <strong>Výchozí a periodické revize:</strong> Nabízíme jak výchozí revize nově instalovaných systémů, tak pravidelné periodické revize, které jsou vyžadovány zákonem pro zajištění dlouhodobé bezpečnosti elektrických zařízení.
          </li>
          <li>
            <strong>Revize a zkoušky hromosvodů:</strong> Speciální služba zaměřená na kontrolu a zkoušky funkčnosti hromosvodů, které zajišťují ochranu budov před úderem blesku.
          </li>
        </ul>

        <h2>Výhody pravidelných revizí a zkoušek:</h2>
        <ul>
          <li>
            <strong>Zajištění bezpečnosti:</strong> Pravidelná revize minimalizuje riziko elektrických požárů, zkratů a dalších nebezpečných situací, které mohou ohrozit zdraví osob a majetek.
          </li>
          <li>
            <strong>Legislativní soulad:</strong> Revize a zkoušky jsou často vyžadovány zákonem a normami. Naše služby vám zajistí, že vaše zařízení bude vždy v souladu s těmito požadavky.
          </li>
          <li>
            <strong>Prevence poruch a nákladných oprav:</strong> Pravidelné kontroly pomáhají odhalit potenciální problémy včas, což může předejít větším škodám a nákladným opravám v budoucnu.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Revize;
