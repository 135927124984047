import React from 'react';
import './Services.css';

function Services() {
  return (
    <div className="services-container">
      <section className="services">
        <h1>Naše služby</h1>
        <p>
          V Elrama s.r.o. nabízíme širokou škálu služeb v oblasti elektroinstalací a fotovoltaiky. Naším cílem je poskytovat komplexní a efektivní řešení pro všechny vaše potřeby v oblasti elektřiny a obnovitelných zdrojů energie.
        </p>
        <ul>
          <li><strong>Nové elektroinstalace a rekonstrukce:</strong> Provádíme kompletní elektroinstalace pro nové budovy i rekonstrukce stávajících objektů. Zajišťujeme veškeré práce od návrhu po finální realizaci.</li>
          <li><strong>Výroba a instalace rozvaděčů:</strong> Na míru vyrábíme a instalujeme rozvaděče pro různé typy objektů, včetně průmyslových a komerčních budov.</li>
          <li><strong>Revize elektroinstalací:</strong> Provádíme pravidelné revize elektroinstalací a fotovoltaických systémů, abychom zajistili jejich bezpečný a bezproblémový provoz.</li>
          <li><strong>Montáž fotovoltaických elektráren:</strong> Nabízíme komplexní služby v oblasti fotovoltaiky, včetně návrhu, montáže, vyřízení dotací, revize a servisu.</li>
          <li><strong>Konzultace a návrhy řešení:</strong> Poskytujeme odborné konzultace a navrhujeme individuální řešení na míru vašim potřebám a požadavkům.</li>
        </ul>
        <p>
          Kontaktujte nás pro více informací o našich službách. Jsme připraveni vám pomoci s jakýmkoliv projektem.
        </p>
      </section>
    </div>
  );
}

export default Services;
