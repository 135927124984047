import React from 'react';
import './Hromosvody.css';

function Hromosvody() {
  return (
    <div className="hromosvody-container">
      <section className="hromosvody-content">
        <h1>Hromosvody</h1>
        <h2>Naše služby v oblasti hromosvodů zahrnují:</h2>

        <ul>
          <li>
            <strong>Návrh a instalace hromosvodů:</strong> Poskytujeme komplexní služby od návrhu až po finální montáž hromosvodů na jakýkoli typ objektu, včetně rodinných domů, průmyslových hal a komerčních budov.
          </li>
          <li>
            <strong>Revize a údržba:</strong> Pravidelné kontroly a údržba hromosvodů jsou klíčové pro jejich spolehlivou funkci. Zajišťujeme, že vaše zařízení bude vždy v souladu s platnými normami a připraveno na případný úder blesku.
          </li>
          <li>
            <strong>Modernizace a opravy:</strong> Nabízíme modernizaci starších hromosvodných systémů tak, aby odpovídaly současným bezpečnostním standardům, a provádíme opravy poškozených částí systému.
          </li>
        </ul>

        <h2>Proč investovat do kvalitního hromosvodu?</h2>
        <ul>
          <li>
            <strong>Maximální bezpečnost:</strong> Hromosvod ochrání vaši budovu a její obyvatele před přímým úderem blesku a s ním spojenými riziky, jako jsou požáry nebo poškození elektroniky.
          </li>
          <li>
            <strong>Soulad s normami:</strong> Naše systémy jsou navrženy a instalovány v souladu s nejnovějšími normami a předpisy, což vám zajistí klid a bezpečí.
          </li>
          <li>
            <strong>Dlouhá životnost a spolehlivost:</strong> Používáme pouze kvalitní materiály a prověřené technologie, které zaručují dlouhou životnost a spolehlivou funkci hromosvodu.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Hromosvody;
