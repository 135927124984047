import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-container">
      <section className="about">
        <h1>O nás</h1>
        <ul>
          <li>Profesionalita</li>
          <li>Spolehlivost</li>
          <li>Inovace</li>
          <li>Individuální přístup</li>
        </ul>
        <p>
          Elrama s.r.o. je rodinná dynamicky se rozvíjející společnost, která se specializuje na elektroinstalace a fotovoltaiku. Naším hlavním cílem je poskytovat vysoce kvalitní služby s důrazem na spokojenost zákazníků a dlouhodobou udržitelnost. Naše společnost klade velký důraz na profesionalitu a inovace. Neustále sledujeme nejnovější trendy a technologie, abychom mohli našim zákazníkům nabídnout ty nejlepší možné řešení.
        </p>
        <p>
          Každý projekt, ať už malý nebo velký, je pro nás výzvou, kterou přijímáme s nadšením a maximální zodpovědností. Spolupracujeme s předními dodavateli a využíváme moderní technologie, abychom zajistili vysokou kvalitu a efektivitu našich služeb. Jsme hrdí na to, že naše práce přispívá k rozvoji obnovitelných zdrojů energie a udržitelnému životnímu prostředí.
        </p>
        <h2>Naše mise</h2>
        <p>
        Naší misí je poskytovat průkopnická a udržitelná řešení v oblasti elektroinstalací a fotovoltaiky, která nejen zvyšují efektivitu a spolehlivost energetických systémů, ale také aktivně přispívají k ochraně životního prostředí a snižování nákladů pro naše zákazníky. Věříme, že kombinace inovativních technologií a zodpovědného přístupu může vést k trvalému zlepšení kvality života a k vytvoření stabilních základů pro budoucí generace. Zároveň se zavazujeme k transparentnímu jednání, efektivnímu využívání zdrojů a budování dlouhodobých partnerství s našimi zákazníky, založených na vzájemné důvěře a spolupráci. Naše práce je zaměřena na vytváření hodnot nejen pro dnešek, ale i pro budoucnost.
        </p>
      </section>
    </div>
  );
}

export default AboutUs;
