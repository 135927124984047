import React from 'react';
import { useNavigate } from 'react-router-dom'; // Pro přesměrování
import Slider from 'react-slick';
import './Products.css';

// Import obrázků a jejich cesty
import Elektroinstalace from '../images/elektroinstalace.png';
import Fotovoltaika from '../images/fotovoltaika.png';
import Revize from '../images/revize.png';
import Konzultace from '../images/konzultace.png';
import VyrobaRozvadecu from '../images/vyroba_rozvadecu.png';
import EZS from '../images/ezs.png';
import Hromosvody from '../images/hromosvody.png';
import Carporty from '../images/carporty.png';
import SpravaBudov from '../images/sprava_budov.png'; // Nahraďte správnou cestou k obrázku
import PozarniUcpavky from '../images/pozarni_ucpavky.png';

const Products = () => {
  const accessGranted = localStorage.getItem('accessGranted') === 'true';
  const navigate = useNavigate(); // Použití pro přesměrování uživatele

  // Funkce pro kontrolu přístupu na jednotlivé stránky
  const handleNavigation = (path) => {
    if (accessGranted) {
      navigate(path); // Pokud je přístup udělen, přesměruje na příslušnou stránku
    } else {
      navigate('/password'); // Pokud přístup není udělen, přesměruje na stránku pro zadání hesla
    }
  };

  const settings = {
    slidesToShow: 5,
    infinite: true,
    speed: 1500, // Rychlejší posouvání (1 sekunda)
    autoplay: true,
    autoplaySpeed: 1000, // Konstantní posouvání
    cssEase: 'linear', // Plynulé posouvání
    pauseOnHover: true, // Zastavení posouvání při najetí myší
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carousel-container">
      <h2>Čím se zabýváme?</h2>
      <Slider {...settings}>
        <div onClick={() => handleNavigation('/elektroinstalace')} className="product-card">
          <img src={Elektroinstalace} alt="Elektroinstalace" />
          <h3>Elektroinstalace</h3>
          <p>Kvalitní elektroinstalace pro domácnosti a firmy.</p>
        </div>
        <div onClick={() => handleNavigation('/fotovoltaika')} className="product-card">
          <img src={Fotovoltaika} alt="Fotovoltaika" />
          <h3>Fotovoltaika</h3>
          <p>Montáž a údržba fotovoltaických systémů.</p>
        </div>
        <div onClick={() => handleNavigation('/revize')} className="product-card">
          <img src={Revize} alt="Revize" />
          <h3>Revize a zkoušky elektrických zařízení</h3>
          <p>Profesionální revize a údržba elektroinstalací.</p>
        </div>
        <div onClick={() => handleNavigation('/konzultace')} className="product-card">
          <img src={Konzultace} alt="Konzultace" />
          <h3>Konzultace</h3>
          <p>Konzultace a návrhy řešení na míru.</p>
        </div>
        <div onClick={() => handleNavigation('/vyroba-rozvadecu')} className="product-card">
          <img src={VyrobaRozvadecu} alt="Výroba rozvaděčů" />
          <h3>Výroba rozvaděčů</h3>
          <p>Výroba a instalace rozvaděčů pro různé typy objektů.</p>
        </div>
        <div onClick={() => handleNavigation('/ezs')} className="product-card">
          <img src={EZS} alt="EZS" />
          <h3>EZS</h3>
          <p>Elektronické zabezpečovací systémy pro ochranu vašeho majetku.</p>
        </div>
        <div onClick={() => handleNavigation('/hromosvody')} className="product-card">
          <img src={Hromosvody} alt="Hromosvody" />
          <h3>Hromosvody</h3>
          <p>Instalace a údržba hromosvodů pro vaši bezpečnost.</p>
        </div>
        <div onClick={() => handleNavigation('/carporty')} className="product-card">
          <img src={Carporty} alt="Carporty" />
          <h3>Carporty s nabíječkou</h3>
          <p>Výstavba a montáž carportů pro ochranu vašeho vozidla.</p>
        </div>
        <div onClick={() => handleNavigation('/sprava-budov')} className="product-card">
          <img src={SpravaBudov} alt="Správa budov (elektro a ezs)" />
          <h3>Správa budov</h3>
          <p>Komplexní správa budov v oblasti elektroinstalací.</p>
        </div>
        <div onClick={() => handleNavigation('/pozarni-ucpavky')} className="product-card">
          <img src={PozarniUcpavky} alt="Požární ucpávky" />
          <h3>Požární ucpávky</h3>
          <p>Klíčový prvek vaší požární ochrany.</p>
        </div>
      </Slider>
    </div>
  );
};

export default Products;
