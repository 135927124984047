import React from 'react';
import './VyrobaRozvadecu.css';

function VyrobaRozvadecu() {
  return (
    <div className="vyroba-rozvadecu-container">
      <section className="vyroba-rozvadecu-content">
        <h1>Výroba rozváděčů</h1>
        <h2>Preciznost a spolehlivost pro každý projekt.</h2>
        <p>
          Rozváděče jsou srdcem každého elektrického systému. Bezpečné a spolehlivé rozváděče zajišťují správné fungování všech elektrických obvodů v domácnostech, průmyslových objektech i komerčních budovách. Naše firma se specializuje na výrobu rozváděčů na míru podle specifických potřeb a požadavků našich zákazníků.
        </p>

        <h2>Naše služby zahrnují:</h2>
        <ul>
          <li>
            <strong>Zakázková výroba rozváděčů:</strong> Navrhujeme a vyrábíme rozváděče přesně podle požadavků klienta, ať už jde o malé bytové rozváděče, nebo složité průmyslové systémy.
          </li>
          <li>
            <strong>Široká škála aplikací:</strong> Vyrábíme rozváděče pro různé účely, včetně silnoproudých a slaboproudých rozvodů, automatizace budov, řízení strojů, nebo fotovoltaických systémů.
          </li>
          <li>
            <strong>Kvalitní materiály a komponenty:</strong> Používáme pouze ověřené a vysoce kvalitní materiály a komponenty od renomovaných dodavatelů, které zajišťují dlouhou životnost a spolehlivost našich rozváděčů.
          </li>
          <li>
            <strong>Certifikace a normy:</strong> Všechny naše rozváděče jsou vyráběny v souladu s platnými normami a předpisy. Zajišťujeme, že každý produkt projde důkladnou kontrolou kvality a je připraven k bezpečnému provozu.
          </li>
        </ul>

        <h2>Výhody našich rozváděčů:</h2>
        <ul>
          <li>
            <strong>Přizpůsobení na míru:</strong> Každý projekt je unikátní a my jsme schopni přizpůsobit rozváděče přesně podle vašich potřeb, ať už se jedná o velikost, konfiguraci nebo specifické funkce.
          </li>
          <li>
            <strong>Rychlé dodací lhůty:</strong> Díky našemu zkušenému týmu a efektivní výrobě jsme schopni dodat rozváděče v krátkém čase, aniž by to ohrozilo kvalitu nebo bezpečnost.
          </li>
          <li>
            <strong>Podpora a servis:</strong> Poskytujeme technickou podporu a poradenské služby od návrhu až po finální instalaci a uvedení rozváděče do provozu.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default VyrobaRozvadecu;
