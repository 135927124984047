import React from 'react';
import './Konzultace.css';

function Konzultace() {
  return (
    <div className="konzultace-container">
      <section className="konzultace-content">
        <h1>Konzultace</h1>
        <h2>Konzultace – Odborné poradenství na míru vašim potřebám</h2>

        <p>
          Naše konzultační služby vám poskytují přístup k odborným znalostem a zkušenostem, které vám pomohou dosáhnout vašich cílů efektivněji a s jistotou. Ať už potřebujete poradit v oblasti technologií, projektového řízení, energetických úspor nebo jiných specializovaných oblastí, jsme tu, abychom vám poskytli cílené a praktické rady.
        </p>

        <h2>Naše konzultační služby zahrnují:</h2>
        <ul>
          <li>
            <strong>Technické konzultace:</strong> Nabízíme odborné poradenství v oblasti elektroinstalací, systémové integrace, automatizace a dalších technických řešení. Pomůžeme vám optimalizovat vaše projekty a zajistit jejich správnou realizaci.
          </li>
          <li>
            <strong>Energetické konzultace:</strong> Poskytujeme poradenství zaměřené na energetickou účinnost a úspory nákladů. Navrhneme vám řešení, která sníží vaši spotřebu energie a zlepší environmentální udržitelnost vašeho objektu.
          </li>
          <li>
            <strong>Projektové řízení:</strong> Naši konzultanti vám pomohou s plánováním, koordinací a řízením projektů. Zajistíme, že vaše projekty budou dokončeny včas, v rámci rozpočtu a s požadovanou kvalitou.
          </li>
        </ul>

        <h2>Proč využít našich konzultací?</h2>
        <ul>
          <li>
            <strong>Odbornost:</strong> Naši konzultanti mají hluboké znalosti a dlouholeté zkušenosti v oboru, což vám poskytuje jistotu, že dostanete přesné a relevantní rady.
          </li>
          <li>
            <strong>Individuální přístup:</strong> Každý klient je jedinečný, proto přizpůsobujeme naše konzultace přesně podle vašich potřeb a specifických požadavků.
          </li>
          <li>
            <strong>Efektivní řešení:</strong> Pomůžeme vám najít nejefektivnější způsoby, jak dosáhnout vašich cílů, a zároveň minimalizovat rizika a náklady.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Konzultace;
