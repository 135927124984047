import React from 'react';
import './Elektroinstalace.css';

function Elektroinstalace() {
  return (
    <div className="elektroinstalace-container">
      <section className="elektroinstalace-content">
        <h1>Elektroinstalace</h1>
        <h2>Zajistěte si spolehlivou a bezpečnou elektroinstalaci pro váš domov nebo podnik.</h2>

        <p>
          V dnešním světě, kde technologie hraje klíčovou roli ve všech aspektech našeho života, je kvalitní elektroinstalace nezbytností. Naše služby v oblasti elektroinstalace vám poskytují nejen bezpečné a efektivní řešení, ale také přidanou hodnotu v podobě moderních a energeticky úsporných technologií.
        </p>

        <h2>S námi získáte:</h2>
        <ul>
          <li>
            <strong>Bezpečnost:</strong> Pečlivě dodržujeme všechny normy a předpisy, aby váš domov či podnik byl chráněn před nebezpečím spojeným s elektřinou.
          </li>
          <li>
            <strong>Efektivitu:</strong> Využíváme nejnovější technologie a postupy k navrhování a instalaci elektroinstalací, které vám pomohou snížit spotřebu energie a optimalizovat provoz vašich zařízení. Díky našim řešením budete moci efektivně řídit energetické náklady a přitom si užívat maximální komfort a funkčnost.
          </li>
          <li>
            <strong>Spolehlivost:</strong> Vždy klademe důraz na kvalitu a preciznost provedení. Naše elektroinstalace jsou navrženy tak, aby dlouhodobě vydržely a fungovaly bez problémů. Používáme pouze osvědčené materiály a moderní technologie, abychom zajistili, že váš elektrický systém bude stabilní, bezpečný a odolný vůči jakýmkoli výpadkům.
          </li>
        </ul>

        <p>
          Ať už stavíte nový dům, renovujete byt nebo modernizujete svou kancelář, jsme tu pro vás. Obraťte se na nás a dopřejte si jistotu, že vaše elektroinstalace bude v těch nejlepších rukou. S námi máte jistotu, že elektřina bude vždy tam, kde ji potřebujete!
        </p>

        <h2>Nabízíme:</h2>
        <ul>
          <li>
            <strong>Silnoproudé elektroinstalace</strong>
            <ul>
              <li>Návrh, instalace a údržba nových elektrických rozvodů</li>
              <li>Instalace zásuvek, vypínačů a osvětlení</li>
              <li>Rekonstrukce stávající elektroinstalace</li>
              <li>Instalace elektrického vytápění</li>
              <li>Průmyslové elektroinstalace</li>
              <li>Komerční elektroinstalace</li>
            </ul>
          </li>
          <li>
            <strong>Slaboproudé elektroinstalace</strong>
            <ul>
              <li>Instalace a konfigurace Ethernet sítí</li>
              <li>Optické kabeláže pro vysokorychlostní přenosy dat</li>
              <li>Instalace Wi-Fi sítí a bezdrátových přístupových bodů</li>
              <li>Kamerové systémy</li>
              <li>Audio a video systémy</li>
              <li>Video telefony a interkom systémy</li>
            </ul>
          </li>
          <li>
            <strong>Úpravy elektroměrových sloupků</strong>
            <ul>
              <li>Modernizace elektroměrových rozváděčů</li>
              <li>Přizpůsobení pro nové tarify a měřící systémy</li>
              <li>Opravy</li>
              <li>Revize</li>
            </ul>
          </li>
          <li>
            <strong>Záruční a pozáruční servis</strong>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Elektroinstalace;
