import React from 'react';
import './Home.css';
import Products from './Products';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {
  return (
    <div className="home-container">

      <section className="home-banner">
        <div className="content">
          <h1>Vítejte na stránkách společnosti Elrama s.r.o.</h1>
          <p>Vaše jistota v oblasti kompletních elektroinstalačních služeb.</p>
        </div>
      </section>

      <Products />
    </div>
  );
}

export default Home;
