import React from 'react';
import './TopBar.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa';  // Import ikon

function TopBar() {
  return (
    <div className="top-bar">
      <div className="container">
        <p>
          <span><FaPhone /> +420776884411</span>
          <span><FaEnvelope /> info@elrama.cz</span>
        </p>
      </div>
    </div>
  );
}

export default TopBar;
