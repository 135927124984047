import React from 'react';
import { Link } from 'react-router-dom';
import './UnderConstruction.css';

function UnderConstruction() {
  return (
    <div className="under-construction-container">
      <div className="message-box">
        <h1>Stránka je v rekonstrukci</h1>
        <p>Omlouváme se za nepříjemnosti, naše stránka právě prochází rekonstrukcí. Prosím, vraťte se později.</p>
      </div>
      <Link to="/password" className="password-link">Přístup na stránku</Link>
    </div>
  );
}

export default UnderConstruction;
