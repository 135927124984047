import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import PasswordPage from './pages/PasswordPage'; // Import the PasswordPage component
import UnderConstruction from './pages/UnderConstruction';
import Header from './components/Header'; // Import the Header component
import SubMenu from './components/SubMenu';
import Footer from './components/Footer'; // Import the Footer component
import TopBar from './components/TopBar';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Elektroinstalace from './pages/Elektroinstalace';
import Fotovoltaika from './pages/Fotovoltaika';
import Revize from './pages/Revize';
import Konzultace from './pages/Konzultace';
import VyrobaRozvadecu from './pages/VyrobaRozvadecu';
import Hromosvody from './pages/Hromosvody';
import EZS from './pages/EZS';
import SpravaBudov from './pages/SpravaBudov';
import PozarniUcpavky from './pages/PozarniUcpavky';
import './App.css';

function App() {
  const accessGranted = localStorage.getItem('accessGranted') === 'true';
  const location = useLocation();

  return (
    <div className="App">
      {accessGranted && location.pathname !== '/' && location.pathname !== '/password' && (
        <>
          <TopBar />
          <Header />
          <SubMenu />
        </>
      )}

      <main>
        <Routes>
          <Route path="/" element={<UnderConstruction />} />
          <Route path="/password" element={<PasswordPage />} />

          {/* Chráněné stránky, přístupné pouze při splnění podmínky */}
          {accessGranted && location.pathname !== '/' && location.pathname !== '/password' ? (
            <>
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/elektroinstalace" element={<Elektroinstalace />} />
              <Route path="/fotovoltaika" element={<Fotovoltaika />} />
              <Route path="/revize" element={<Revize />} />
              <Route path="/konzultace" element={<Konzultace />} />
              <Route path="/vyroba-rozvadecu" element={<VyrobaRozvadecu />} />
              <Route path="/hromosvody" element={<Hromosvody />} />
              <Route path="/ezs" element={<EZS />} />
              <Route path="/sprava-budov" element={<SpravaBudov />} />
              <Route path="/pozarni-ucpavky" element={<PozarniUcpavky />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </main>
      {accessGranted && location.pathname !== '/' && location.pathname !== '/password' && <Footer />}
    </div>
  );
}

export default App;
