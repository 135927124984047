import React from 'react';
import './Fotovoltaika.css';

function Fotovoltaika() {
  return (
    <div className="fotovoltaika-container">
      <section className="fotovoltaika-content">
        <h1>Fotovoltaické systémy</h1>
        <h2>Fotovoltaické systémy – Vaše cesta k energetické nezávislosti</h2>

        <p>
          Vstupte do světa čisté a udržitelné energie s našimi fotovoltaickými systémy. Nabízíme kompletní služby v oblasti fotovoltaiky, které vám pomohou využít sluneční energii a snížit vaše náklady na elektřinu. Naše systémy jsou navrženy tak, aby maximalizovaly vaši energetickou nezávislost a přispěly k ochraně životního prostředí.
        </p>

        <h2>Naše služby v oblasti fotovoltaických systémů zahrnují:</h2>
        <ul>
          <li>
            <strong>Návrh a instalace fotovoltaických systémů:</strong> Navrhujeme a instalujeme fotovoltaické panely na míru podle specifikací vaší střechy nebo jiného vhodného prostoru. Zajistíme optimální umístění a konfiguraci systému pro maximální efektivitu.
          </li>
          <li>
            <strong>Výběr a instalace bateriových úložišť:</strong> Pro zvýšení vaší energetické nezávislosti nabízíme také instalaci bateriových úložišť, která umožňují ukládání přebytečné energie pro pozdější použití, například během noci nebo při výpadku proudu.
          </li>
          <li>
            <strong>Připojení do sítě a vyřizování dotací:</strong> Zajistíme připojení vašeho systému do distribuční sítě a pomůžeme vám s vyřízením potřebné dokumentace.
          </li>
          <li>
            <strong>Vyřízení dotací:</strong> Pomůžeme vám s vyřízením žádosti o dotaci, které mohou výrazně snížit vaše počáteční investiční náklady.
          </li>
          <li>
            <strong>Monitoring a údržba:</strong> Poskytujeme služby monitorování a pravidelné údržby, aby váš fotovoltaický systém fungoval efektivně a dlouhodobě.
          </li>
        </ul>

        <h2>Výhody našich fotovoltaických systémů:</h2>
        <ul>
          <li>
            <strong>Úspora nákladů na elektřinu:</strong> Díky výrobě vlastní energie z obnovitelných zdrojů můžete výrazně snížit své měsíční výdaje za elektřinu.
          </li>
          <li>
            <strong>Ekologický přínos:</strong> Využíváním solární energie přispíváte k ochraně životního prostředí a snižujete svou uhlíkovou stopu.
          </li>
          <li>
            <strong>Energetická nezávislost:</strong> S vlastním fotovoltaickým systémem a bateriovým úložištěm se stáváte méně závislými na dodavatelích energie a výkyvech cen na trhu.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Fotovoltaika;
