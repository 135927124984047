import React from 'react';
import { Link } from 'react-router-dom';
import './SubMenu.css';

function SubMenu() {
  return (
    <div className="sub-menu-container">
      <nav className="sub-menu">
        <ul>
          <li><Link to="/fotovoltaika">Fotovoltaika</Link></li>
          <li><Link to="/elektroinstalace">Elektroinstalace</Link></li>
          <li><Link to="/konzultace">Konzultace</Link></li>
          <li><Link to="/vyroba-rozvadecu">Výroba rozvaděčů</Link></li>
          <li><Link to="/ezs">EZS</Link></li>
          <li><Link to="/sprava-budov">Správa budov</Link></li>
          <li><Link to="/pozarni-ucpavky">Požární ucpávky</Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default SubMenu;
